<template>
  <app-header/>
    <Suspense>
      <template #default>
        <app-games :gid='gid'/>
      </template>
      <template #fallback>
        <app-loading />
      </template>
    </Suspense>
  <!-- <app-footer/> -->
</template>

<script>
// import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { Suspense } from 'vue'
import AppGames from '../../components/Pages/AppGames.vue'
export default {
  components: { AppHeader, AppLoading, Suspense, AppGames },
  props: {
    gid: String
  },
   name: 'PlayGames'
}
</script>

<style>

</style>